<template>
  <div class="mt-6 md:mt-8">
    <div class="vx-col mx-auto self-center">
      <vx-card class="rounded-lg">
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col w-full d-theme-dark-bg"
            >
              <div class="px-4 md:px-8 py-4 md:py-6">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-2 lg:text-xl text-lg">Change password</h4>
                  <hr class="line-hr mb-6"/>
                  <p class="font-light text-base mb-10">Please enter your new password.</p>
                  <!-- <vs-alert
                    :active.sync="showDismissibleAlert"
                    closable
                    icon-pack="feather"
                    close-icon="icon-x"
                  >{{message}}</vs-alert> -->
                </div>
                <div class="vs-row grid md:grid-cols-2 lg:grid-cols-3 md:gap-x-16 lg:gap-x-24">
                <form class="postion-icon">
                  <div class="passwordField mb-6 mt-0">
                    <label class="w-full mb-2 text-base">Current password</label>
                    <vs-input
                      data-vv-validate-on="blur"
                      v-validate="'required'"
                      :type="passwordFieldType"
                      name="currentPassword"
                      label-placeholder=""
                      id="currentPassword"
                      icon-no-border
                      icon-after="true"
                      icon-pack="feather"
                      :icon="passwordShowIcon"
                      v-model="user.currentPassword"
                      class="passwordInput"
                      @click.native="switchPasswordVisibility($event)"
                    />
                    <span class="text-danger text-sm ">{{
                      errors.first("currentPassword")
                    }}</span>
                  </div>
                  <div class="passwordField mb-6 mt-0">
                    <label class="w-full mb-2 text-base">New password</label>
                    <vs-input
                      data-vv-validate-on="blur"
                      v-validate="'required|passwordFormat'"
                      :type="passwordFieldType"
                      name="newPassword"
                      label-placeholder=""
                      id="newPassword"
                      icon-no-border
                      icon-after="true"
                      icon-pack="feather"
                      :icon="passwordShowIcon"
                      v-model="user.newPassword"
                      class="passwordInput"
                      @click.native="switchPasswordVisibility($event)"
                    />
                    <span class="text-danger text-sm">{{
                      errors.first("newPassword")
                    }}</span>
                  </div>
                  <div class="passwordField mb-6 mt-0">
                    <label class="w-full mb-2 text-base">Confirm new password</label>
                    <vs-input
                      data-vv-validate-on="blur"
                      v-validate="'required'"
                      :type="passwordFieldType"
                      name="confirmNewPassword"
                      label-placeholder=""
                      id="confirmNewPassword"
                      icon-no-border
                      icon-after="true"
                      icon-pack="feather"
                      :icon="passwordShowIcon"
                      v-model="user.confirmNewPassword"
                      class="passwordInput"
                      @click.native="switchPasswordVisibility($event)"
                    />
                    <span class="text-danger text-sm">{{
                      errors.first("confirmNewPassword")
                    }}</span>
                  </div>
                </form>
                </div>
                
                <vs-row vs-justify="flex-end" vs-w="12" class="flex items-center mt-4 mb-4 lg:mb-6">                  
                  <vs-button
                    class="mr-10 large"
                    :disabled="!validateForm"
                    @click="changeAdminPassword"
                    v-round
                    >Save</vs-button
                  >
                  <a
                    class="text-button text-sm"                   
                    @click="$router.replace('dashboard')"
                    >Cancel</a
                  >
                </vs-row>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";

const dictionary = {
  en: {
    attributes: {
      currentPassword: "current password",
      newPassword: "new password",
      confirmNewPassword: "confirm new password"
    }
  }
};
Validator.localize(dictionary);
export default {
  data() {
    return {
      user: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
      },
      passwordFieldType: "password",
      passwordShowIcon: "icon icon-eye-off",
      newPasswordFieldType: "password",
      newPasswordShowIcon: "visibility",
      confirmPasswordFieldType: "password",
      confirmPasswordShowIcon: "visibility",
      iconWrap: {
        marginTop: "-35px"
      }
    };
  },
  computed: {
    validateForm() {
      return true;
    }
  },
  methods: {
    ...mapActions("admin", ["changePassword", "checkLogin"]),
    changeAdminPassword() {
      const payload = {
        userDetails: this.user
      };
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.user.newPassword == this.user.confirmNewPassword) {
            this.$vs.loading();
            this.changePassword(payload)
              .then(response => {
                this.$vs.loading.close();
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Message",
                  text: response.data.message,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "success"
                });

                this.$router.push("/view/profile");
              })
              .catch(error => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error",
                  text: error.message,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger"
                });
              });
          }else{
            this.errors.add({
             field: 'confirmNewPassword',
             msg: 'The confirm new password confirmation does not match'
           })
          }
        }
      });
    },

    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordShowIcon =
        this.passwordShowIcon === "icon icon-eye"
          ? "icon icon-eye-off"
          : "icon icon-eye";
    },

    switchNewPasswordVisibility() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.newPasswordShowIcon =
        this.newPasswordShowIcon === "visibility"
          ? "visibility_off"
          : "visibility";
    },

    switchConfirmPasswordVisibility() {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === "password" ? "text" : "password";
      this.confirmPasswordShowIcon =
        this.confirmPasswordShowIcon === "visibility"
          ? "visibility_off"
          : "visibility";
    },
    async checkAdminLogin() {
      await this.checkLogin();
    },
  },
  created() {
    this.$validator.extend("passwordFormat", {
      getMessage: () =>
        "Password must contain at least one uppercase, lowercase, digit, one special character and 10 characters long",
      validate: value =>
        value.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{10,}$/
        ) !== null
    });
    this.checkAdminLogin();
  }
};
</script>
